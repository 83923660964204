import { Sprite } from 'pixi.js';

import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { uiAlignCenter, uiAlignCenterX, uiScaleToWidth } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { pixiConfig } from '../../defs/config';
import { LayoutScreen3 } from '../../lib/screens/LayoutScreen3';
import { BasicText } from '../../lib/ui/text/BasicText';
import { TextInput } from '../../lib/ui/text/TextInput';

//-----------------------------------------------------------------------------
export type OpenHomePopupOptions = {};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    close: 'close-x.png',
    arrow: 'up-arrow.png',
    button: 'button-open.png',
};

/*
    Transparent overlay popup, used for tutorial pet name
*/
export class OpenHomePopup extends LayoutScreen3 {
    // fields
    //-------------------------------------------------------------------------
    private _input: TextInput;
    private _topFrame: Sprite;
    private _arrow: Sprite;

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...Object.values(manifest)]);
    }

    public async spawning(options: OpenHomePopupOptions) {
        // spawn scene
        this._spawn(options);
    }

    public async despawned() {
        this.empty();
        this.root.removeChildren();
        //this._input.stop();
    }

    public async despawning() {
        await this.animateOut();
    }

    public async spawned(): Promise<void> {
        //this._input.start();
        await this.animateIn();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        //uiAlignBottom(size, this._topFrame, 200);
        uiScaleToWidth(this._topFrame, size.width * 0.7);
        uiAlignCenterX(size, this._topFrame);
        this._topFrame.y =
            150 * Math.max(size.height / size.width / (pixiConfig.size.height / pixiConfig.size.width), 1);
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: OpenHomePopupOptions) {
        const topFrame = (this._topFrame = Sprite.from(manifest.button));
        this.root.addChild(topFrame);

        const header = topFrame.addChild(
            new BasicText({
                text: '[pwaOpen]',
                style: {
                    fill: '#fff',
                    fontSize: 34,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                },
            }),
        );
        uiAlignCenter(topFrame, header);
        header.x -= 55;

        Object.assign(new TouchInputComponent(topFrame), {
            onTap: async () => {
                app.nav.close('openHomePopup');
                console.log('PWA: OpenHomePopup: TAPPED');
                window.open(window.location.origin, '_blank');
            },
        });
    }

    private async animateIn() {
        const y = this._topFrame.y;
        this._topFrame.y -= 250;
        await this._topFrame.animate().add(this._topFrame, { y }, 0.5, tween.backOut(1.4));
    }

    private async animateOut() {
        await this._topFrame.animate().add(this._topFrame, { y: this._topFrame.y - 250 }, 0.35, tween.backIn(1.4));
    }
}
