/**
 * This mini-lib is an attempt at pure functional parsing (decoding) in TypeScript.
 *
 * It was added to support the writing of complex, composable logic and to separate the implementation concerns of SchemaBuilder from the new
 * requirements of the virtual state feature.
 *
 * Some influences:
 * - https://lexi-lambda.github.io/blog/2019/11/05/parse-don-t-validate/
 * - https://github.com/gcanti/io-ts
 */

import { DecodeError, Decoder, MultipleErrors, Result, unwrap } from './types';

export * from './combinators';
export * from './decoders';
export * from './types';
export * from './validators';

export { default } from './combinators';

export function decode<A>(decoder: Decoder<A>, input: unknown): Result<MultipleErrors, A> {
    return unwrap(decoder)(input);
}

export function formatError(error: DecodeError): string {
    switch (error[0]) {
        case 'DecodeError':
            return error[1];
        case 'ErrorAtIndex':
            return 'Error at array index  ' + error[1] + ': ' + formatError(error[2]);
        case 'ErrorAtProperty':
            return 'Error at property ' + error[1] + ': ' + formatError(error[2]);
        case 'TypeMismatch':
            return 'Type mismatch: expected ' + error[1] + ', found ' + error[2];
    }
}
