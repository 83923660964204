/* eslint-disable max-classes-per-file */
import * as d from '../utils/decoders';
import { deepCopy } from '../utils/ObjUtils';
import { Filter, FilterOut, NoAny } from '../utils/TypeUtils';

export type ValidationOpts = {
    ignoreUnexpectedFields?: boolean;
};

export abstract class Schema<TSchema = any> {
    readonly __schema: boolean = true;
    required: boolean = true;

    protected defaultValue: TSchema | undefined;

    // Used when deducing the default value for optional types. If default is explicitly set, return that value, else
    // return undefined.
    protected defaultValueSet: boolean = false;
    // Used to identify schema objects to enforce that such are used when building complex schemas
    // Useful only for pure JS, where types are not enforced.

    abstract readonly _type:
        | 'array'
        | 'boolean'
        | 'integer'
        | 'map'
        | 'number'
        | 'object'
        | 'string'
        | 'tuple'
        | 'union'
        | 'unknown';

    isArraySchema(): this is ArraySchema<any> {
        return this._type === 'array';
    }

    isObjectSchema(): this is ObjectSchema<any> {
        return this._type === 'object';
    }

    optional(): this & Optional<TSchema> {
        const schemaClone = this.clone() as this & Optional<TSchema>;
        schemaClone.required = false;
        return schemaClone;
    }

    default(val: TSchema): this {
        this.tryValidate(val);

        const schemaClone = this.clone();
        schemaClone.defaultValue = val;
        schemaClone.defaultValueSet = true;

        return schemaClone;
    }

    customValidator(validateFn: (val: TSchema, opts?: ValidationOpts) => string | null) {
        this.customValidatorFn = validateFn;
        return this;
    }

    getDefault(): TSchema | undefined {
        this.tryValidate(this.defaultValue);

        if (!this.required && !this.defaultValueSet) {
            return undefined;
        }

        return deepCopy(this.defaultValue);
    }

    tryValidate(val: any, opts?: ValidationOpts) {
        const validationError = this.validate(val, opts);
        if (validationError) {
            throw new Error(validationError);
        }
    }

    validate(val: any, opts?: ValidationOpts): string | null {
        const validationTypeError = this.validateType(val, opts);
        if (validationTypeError) {
            return validationTypeError;
        }

        return this.required || val !== undefined ? this.customValidatorFn(val, opts) : null;
    }

    isValid(val: any, opts?: ValidationOpts): val is TSchema {
        return !this.validate(val, opts);
    }

    //

    tryValidateAndFillDefaults(val: any, opts?: ValidationOpts): TSchema | null {
        if (val === undefined && this.required) {
            return this.getDefault()!;
        }

        this.tryValidate(val, opts);

        return null;
    }

    /**
     * Decode some input, returning the decoded value or throwing an exception. Decoded values are partial clones of the input, extra properties
     * will be pruned, and missing values will be filled with defaults.
     */
    tryDecode(input: unknown): TSchema {
        const result = d.decode(this.toDecoder(), input);
        if (d.isOk(result)) {
            return result[1];
        } else {
            throw new Error(result[1].map(d.formatError).join('\n'));
        }
    }

    protected clone(): this {
        return deepCopy(this);
    }

    protected customValidatorFn: (val: TSchema, opts?: ValidationOpts) => string | null = () => null;

    abstract serialize(): object;

    /** @internal */
    abstract toDecoder(): d.Decoder<TSchema>;

    //

    protected abstract validateType(_: TSchema | unknown, opts?: ValidationOpts): string | null;
}

export type Optional<T> = {
    required: false;
    unused?: T; // TypeScript hack, otherwise type T is stripped.
};

// Type utils.

// Identity type transformation to merge multiple types in a sum type
type Id<T> = { [k in keyof T]: T[k] } & {};

// prettier-ignore
export type ExtractType<TSchema> =
    TSchema extends Optional<infer U> ? U | undefined :
    TSchema extends Schema<infer T> ? T :
    never;

// Specific validators.
export class NumberSchema extends Schema<number> {
    _type: 'number' | 'integer' = 'number';

    protected minVal: number = -Infinity;
    protected maxVal: number = +Infinity;

    constructor() {
        super();
        this.defaultValue = 0;
    }

    range(min: number, max: number) {
        const schemaClone = this.clone();
        schemaClone.minVal = min;
        schemaClone.maxVal = max;
        return schemaClone;
    }

    min(val: number) {
        const schemaClone = this.clone();
        schemaClone.minVal = val;
        return schemaClone;
    }

    max(val: number) {
        const schemaClone = this.clone();
        schemaClone.maxVal = val;
        return schemaClone;
    }

    validateType(val: any): string | null {
        if (val === undefined && !this.required) {
            return null;
        }

        if (typeof val !== 'number') {
            return 'Not a number: ' + val;
        }

        if (this.minVal > val || this.maxVal < val) {
            return 'Out of range: ' + val + ' in range [' + this.minVal + ', ' + this.maxVal + ']';
        }

        if (isNaN(val)) {
            return 'Value is NaN';
        }

        return null;
    }

    serialize() {
        return {
            type: 'number',
            optional: !this.required,
            ...(Number.isSafeInteger(this.minVal) ? { minVal: this.minVal } : {}),
            ...(Number.isSafeInteger(this.maxVal) ? { maxVal: this.maxVal } : {}),
        };
    }

    toDecoder(): d.Decoder<number> {
        let decoder: d.Decoder<number> = d.number;
        decoder = d.min(this.minVal, decoder);
        decoder = d.max(this.maxVal, decoder);
        return extendFromSchema(this, decoder);
    }
}

export class IntegerSchema extends NumberSchema {
    _type = 'integer' as const;

    constructor() {
        super();
    }

    validateType(val: any): string | null {
        if (val === undefined && !this.required) {
            return null;
        }

        const numberValidationError = super.validateType(val);

        if (numberValidationError) {
            return numberValidationError;
        }

        if (parseInt(val.toString(), 10) !== val) {
            return 'Not an integer';
        }

        return null;
    }

    toDecoder(): d.Decoder<number> {
        let decoder: d.Decoder<number> = d.int;
        decoder = d.min(this.minVal, decoder);
        decoder = d.max(this.maxVal, decoder);
        return extendFromSchema(this, decoder);
    }
}

export class BooleanSchema extends Schema<boolean> {
    _type = 'boolean' as const;

    constructor() {
        super();
        this.defaultValue = false;
    }

    validateType(val: any): string | null {
        if (val === undefined && !this.required) {
            return null;
        }

        if (typeof val !== 'boolean') {
            return 'Not a boolean';
        }

        return null;
    }

    serialize() {
        return { type: 'boolean', optional: !this.required };
    }

    toDecoder(): d.Decoder<boolean> {
        return extendFromSchema(this, d.boolean);
    }
}

export class StringSchema extends Schema<string> {
    _type = 'string' as const;

    constructor() {
        super();
        this.defaultValue = '';
    }

    validateType(val: any): string | null {
        if (val === undefined && !this.required) {
            return null;
        }

        return typeof val === 'string' ? null : 'Not a string: ' + val;
    }

    serialize() {
        return { type: 'string', optional: !this.required };
    }

    toDecoder(): d.Decoder<string> {
        return extendFromSchema(this, d.string);
    }
}

// prettier-ignore
type ExtractObjectSchema<TSchema extends { [k: string]: Schema<any> }> =
    NoAny<TSchema> extends never ? any : // Make `ExtractObjectSchema<any>` compatible with any object schema type
    Id<
        { [k in keyof Filter<TSchema, Optional<any>>]?: TSchema[k] extends Schema<infer P> ? P : never } &
        { [k in keyof FilterOut<TSchema, Optional<any>>]: TSchema[k] extends Schema<infer P> ? P : never }
      >;

export class ObjectSchema<TSchema extends { [k: string]: Schema<any> }, TAdditional = {}> extends Schema<
    ExtractObjectSchema<TSchema> & TAdditional
> {
    _type = 'object' as const;

    private objValidators: TSchema;
    private additionalPropertyValuesSchema?: Schema;

    constructor(objValidators: TSchema) {
        super();

        this.objValidators = objValidators;

        for (const key in objValidators) {
            if (typeof objValidators[key] !== 'object' || !objValidators[key]?.__schema) {
                throw new Error('Schema for field ' + key + ' not constructed by SchemaBuilder.');
            }
        }
    }

    validateType(val: any, opts?: ValidationOpts): string | null {
        if (val === undefined && !this.required) {
            return null;
        }

        if (typeof val !== 'object' || val === null) {
            return 'Not an object: ' + val;
        }

        for (const key in val) {
            let validator = this.objValidators[key];
            const ignoreUnexpectedFields = opts && !!opts.ignoreUnexpectedFields;

            if (!validator) {
                if (ignoreUnexpectedFields) {
                    continue;
                } else if (!this.additionalPropertyValuesSchema) {
                    return 'Unexpected field: ' + key;
                }

                validator = this.additionalPropertyValuesSchema;
            }

            const keyValidationResult = validator.validate(val[key], opts);
            if (keyValidationResult) {
                return 'Error validating field `' + key + '`: ' + keyValidationResult;
            }
        }

        // Check whether we're missing some fields.
        const requiredFields = Object.keys(this.objValidators).filter((x) => {
            const validator = this.objValidators[x]!;
            return validator.required;
        });

        const missingFields = requiredFields.filter((x) => typeof val[x] === 'undefined');

        if (missingFields.length > 0) {
            return 'Missing fields: ' + missingFields.join(', ');
        }

        return null;
    }

    tryValidateAndFillDefaults(val: any, opts?: ValidationOpts): (ExtractObjectSchema<TSchema> & TAdditional) | null {
        if (val === undefined && !this.required) {
            return null;
        }

        if (typeof val !== 'object' || val === null) {
            throw new Error('Not an object.');
        }

        let changed = false;

        for (const key in val) {
            let validator = this.objValidators[key];
            const ignoreUnexpectedFields = opts && !!opts.ignoreUnexpectedFields;

            if (!validator) {
                if (ignoreUnexpectedFields) {
                    continue;
                } else if (!this.additionalPropertyValuesSchema) {
                    throw new Error('Unexpected field: ' + key);
                }

                validator = this.additionalPropertyValuesSchema;
            }

            let filledDefaults;

            try {
                filledDefaults = validator.tryValidateAndFillDefaults(val[key], opts);
            } catch (e: any) {
                throw new Error('Error validating field `' + key + '`: ' + e.message);
            }

            if (filledDefaults) {
                if (!changed) {
                    val = deepCopy(val);
                    changed = true;
                }

                val[key] = filledDefaults;
            }
        }

        // Check whether we're missing some fields.
        const requiredFields = Object.keys(this.objValidators).filter((x) => {
            const validator = this.objValidators[x]!;
            return validator.required;
        });
        const missingFields = requiredFields.filter((x) => typeof val[x] === 'undefined');

        for (const field of missingFields) {
            if (!changed) {
                val = deepCopy(val);
                changed = true;
            }

            val[field] = this.objValidators[field]!.getDefault()!;
        }

        return changed ? val : null;
    }

    getDefault(): (ExtractObjectSchema<TSchema> & TAdditional) | undefined {
        if (!this.required && !this.defaultValueSet) {
            return undefined;
        }

        let defValue;
        if (this.defaultValue) {
            defValue = this.defaultValue;
        } else {
            // Construct a default from the value defaults
            defValue = Object.keys(this.objValidators)
                .map((key) => ({
                    [key]: this.objValidators[key]!.getDefault(),
                }))
                .reduce((obj, x) => ({ ...obj, ...x }), {}) as any;
        }

        this.tryValidate(defValue);
        return deepCopy(defValue);
    }

    serialize() {
        const fields: { [key: string]: object } = {};
        for (const key in this.objValidators) {
            fields[key] = this.objValidators[key]!.serialize();
        }
        return { type: 'object', optional: !this.required, fields };
    }

    getSchema<K extends keyof TSchema>(key: K): TSchema[K] {
        return this.objValidators[key];
    }

    getFullSchema(): TSchema {
        return this.objValidators;
    }

    getAdditionalPropertyValuesSchema(): Schema | undefined {
        return this.additionalPropertyValuesSchema;
    }

    /**
     * Allow additional properties in object.
     * @param schema schema used to validate additional property values, defaults to `unknown`
     */
    additionalProperties(): ObjectSchema<TSchema, { [key: string]: unknown }>;

    additionalProperties<TAdditionalPropertyValues extends Schema>(
        schema: TAdditionalPropertyValues,
    ): ObjectSchema<TSchema, { [key: string]: ExtractType<TAdditionalPropertyValues> }>;

    additionalProperties<TAdditionalPropertyValues extends Schema>(schema?: TAdditionalPropertyValues) {
        const schemaClone = this.clone();
        schemaClone.additionalPropertyValuesSchema = schema || unknown();

        return schemaClone as unknown as ObjectSchema<
            TSchema,
            { [key: string]: ExtractType<TAdditionalPropertyValues> }
        >;
    }

    toDecoder(): d.Decoder<ExtractObjectSchema<TSchema> & TAdditional> {
        // for SB compatibility the result type has to omit optionality (unions with undefined)

        const fields: Record<string, d.Decoder<any>> = {};
        Object.entries(this.objValidators).forEach(([field, schema]) => {
            fields[field] = schema.toDecoder();
        });
        let decoder: d.Decoder<any> = d.record(fields);
        if (this.additionalPropertyValuesSchema) {
            const extendedDecoder = this.additionalPropertyValuesSchema.toDecoder();
            decoder = d.bind(decoder, (base) =>
                d.bind(d.stringMap(d.any), (input) => {
                    const extended: Record<string, any> = {};
                    let result;
                    Object.entries(input).forEach(([key, value]) => {
                        result = d.decode(extendedDecoder, value);
                        if (d.isOk(result)) {
                            extended[key] = result[1];
                        }
                    });
                    return d.pure({ ...extended, ...base } as ExtractObjectSchema<TSchema> & TAdditional);
                }),
            );
        }
        return extendFromSchema(this, decoder);
    }
}

export class ArraySchema<TItemSchema> extends Schema<TItemSchema[]> {
    _type = 'array' as const;

    private itemSchema: Schema<TItemSchema>;

    private minLengthVal: number = -Infinity;
    private maxLengthVal: number = +Infinity;

    constructor(itemSchema: Schema<TItemSchema>) {
        super();

        this.defaultValue = [];

        this.itemSchema = itemSchema;

        if (typeof itemSchema !== 'object' || !itemSchema.__schema) {
            throw new Error('Item schema for array not constructed by SchemaBuilder');
        }
    }

    lengthRange(min: number, max: number) {
        const schemaClone = this.clone();
        schemaClone.minLengthVal = min;
        schemaClone.maxLengthVal = max;
        return schemaClone;
    }

    minLength(val: number) {
        const schemaClone = this.clone();
        schemaClone.minLengthVal = val;
        return schemaClone;
    }

    maxLength(val: number) {
        const schemaClone = this.clone();
        schemaClone.maxLengthVal = val;
        return schemaClone;
    }

    length(val: number) {
        const schemaClone = this.clone();
        schemaClone.minLengthVal = val;
        schemaClone.maxLengthVal = val;
        return schemaClone;
    }

    validateType(val: any, opts?: ValidationOpts): string | null {
        if (val === undefined && !this.required) {
            return null;
        }

        if (typeof val !== 'object' || !Array.isArray(val)) {
            return 'Not an array ' + val;
        }

        if (this.minLengthVal > val.length || this.maxLengthVal < val.length) {
            return `Length out of range: ${val.length} in range [${this.minLengthVal}, ${this.maxLengthVal}]`;
        }

        for (let i = 0; i < val.length; i++) {
            // If we've stringified the val, we may get nulls here. Convert to undefined to pass validation.
            const item = val[i] ?? undefined;

            const validationResult = this.itemSchema.validate(item, opts);
            if (validationResult) {
                return 'Error validating item ' + i + ': ' + validationResult;
            }
        }

        return null;
    }

    tryValidateAndFillDefaults(val: any, opts?: ValidationOpts): TItemSchema[] | null {
        if (val === undefined && !this.required) {
            return null;
        }

        let change = false;

        for (let i = 0; i < val.length; i++) {
            // If we've stringified the val, we may get nulls here. Convert to undefined to pass validation.
            const item = val[i] ?? undefined;

            let filled: TItemSchema | null;

            try {
                filled = this.itemSchema.tryValidateAndFillDefaults(item, opts);
            } catch (e: any) {
                throw new Error('Error validating item ' + i + ': ' + e.message);
            }

            if (filled !== null) {
                if (!change) {
                    val = deepCopy(val);
                }
                val[i] = filled;
                change = true;
            }
        }

        this.tryValidate(val, opts);

        return change ? val : null;
    }

    getDefault() {
        if (!this.required && !this.defaultValueSet) {
            return undefined;
        }

        this.tryValidate(this.defaultValue);
        return deepCopy(this.defaultValue);
    }

    serialize() {
        return {
            type: 'array',
            optional: !this.required,
            items: this.itemSchema.serialize(),
            ...(Number.isSafeInteger(this.minLengthVal) ? { minLengthVal: this.minLengthVal } : {}),
            ...(Number.isSafeInteger(this.maxLengthVal) ? { maxLengthVal: this.maxLengthVal } : {}),
        };
    }

    getItemSchema(): Schema<TItemSchema> {
        return this.itemSchema;
    }

    toDecoder(): d.Decoder<TItemSchema[]> {
        const itemDecoder = this.itemSchema.toDecoder();
        let decoder: d.Decoder<TItemSchema[]> = d.array(
            // try to reproduce SBs special handling for nulls that _might_ have been caused by JSON serialisation
            d.wrap((input) => d.decode(itemDecoder, input ?? undefined)),
        );
        decoder = d.minLength(this.minLengthVal, decoder);
        decoder = d.maxLength(this.maxLengthVal, decoder);
        return extendFromSchema(this, decoder);
    }
}

export class TupleSchema<T extends string> extends Schema<T> {
    _type = 'tuple' as const;

    constructor(protected values: [T, ...T[]]) {
        super();

        if (values.length === 0) {
            throw new Error('Tuple schema requires at least one string literal type.');
        }
    }

    validateType(val: any): string | null {
        if (val === undefined && !this.required) {
            return null;
        }

        if (!this.values.includes(val)) {
            return val + ' is not valid, possible values: ' + this.values.join(', ');
        }

        return null;
    }

    getDefault(): T | undefined {
        if (!this.required && !this.defaultValueSet) {
            return undefined;
        }

        if (this.defaultValueSet) {
            return this.defaultValue;
        }

        return this.values[0];
    }

    serialize() {
        return { type: 'tuple', optional: !this.required, values: this.values };
    }

    toDecoder(): d.Decoder<T> {
        const decoder: d.Decoder<T> = d.bind(d.string, (value) => {
            if (this.values.includes(value as T)) return d.pure(value as T);
            return d.fail('Expected one of { ' + this.values.map((x) => JSON.stringify(x)).join(', ') + ' }');
        });
        return extendFromSchema(this, decoder);
    }
}

export class MapSchema<TItemSchema> extends Schema<{ [key: string]: TItemSchema }> {
    _type = 'map' as const;

    private itemSchema: Schema<TItemSchema>;

    constructor(itemSchema: Schema<TItemSchema>) {
        super();

        this.itemSchema = itemSchema;
        if (typeof itemSchema !== 'object' || !itemSchema.__schema) {
            throw new Error('Item schema for array not constructed by SchemaBuilder');
        }

        this.defaultValue = {};
    }

    validateType(val: any, opts?: ValidationOpts): string | null {
        if (val === undefined && !this.required) {
            return null;
        }

        if (typeof val !== 'object' || val === null) {
            return 'Not a map: ' + val;
        }

        for (const key in val) {
            const validationError = this.itemSchema.validate(val[key], opts);
            if (validationError) {
                return 'Error validating item in map with key "' + key + '": ' + validationError;
            }
        }

        return null;
    }

    tryValidateAndFillDefaults(val: any, opts?: ValidationOpts) {
        if (this.required && val === undefined) {
            return this.getDefault()!;
        }

        if (val === undefined && !this.required) {
            return null;
        }

        if (typeof val !== 'object' || val === null) {
            return 'Not a map: ' + val;
        }

        let change = false;
        for (const key in val) {
            let filled: TItemSchema | null;
            try {
                filled = this.itemSchema.tryValidateAndFillDefaults(val[key], opts);
            } catch (e: any) {
                throw new Error('Error validating item in map with key "' + key + '": ' + e.message);
            }

            if (filled) {
                if (!change) {
                    val = deepCopy(val);
                }
                val[key] = filled;
                change = true;
            }
        }

        return change ? val : null;
    }

    getDefault() {
        if (!this.required && !this.defaultValueSet) {
            return undefined;
        }

        this.tryValidate(this.defaultValue);
        return deepCopy(this.defaultValue);
    }

    getItemSchema() {
        return this.itemSchema;
    }

    serialize() {
        return { type: 'map', optional: !this.required, items: this.itemSchema.serialize() };
    }

    toDecoder(): d.Decoder<{ [key: string]: TItemSchema }> {
        const decoder: d.Decoder<{ [key: string]: TItemSchema }> = d.stringMap(this.itemSchema.toDecoder());
        return extendFromSchema(this, decoder);
    }
}

export class UnionSchema<TItemSchema> extends Schema<ExtractType<TItemSchema>> {
    _type = 'union' as const;

    private itemSchemas: Schema[];

    constructor(itemSchemas: Schema[]) {
        super();

        if (itemSchemas.length === 0) {
            throw new Error('Cannot define empty union type');
        }

        this.itemSchemas = itemSchemas;
    }

    validateType(val: any, opts?: ValidationOpts): string | null {
        if (val === undefined && !this.required) {
            return null;
        }

        const errors: string[] = [];

        for (const schema of this.itemSchemas) {
            const validationResult = schema.validate(val, opts);
            if (validationResult) {
                errors.push('Could not validate value as ' + schema.constructor.name + ': ' + validationResult);
            } else {
                return null;
            }
        }

        return errors.join('\n');
    }

    getDefault(): ExtractType<TItemSchema> | undefined {
        if (!this.required && !this.defaultValueSet) {
            return undefined;
        }

        if (this.defaultValueSet) {
            return this.defaultValue;
        }

        return this.itemSchemas[0]!.getDefault();
    }

    getItemSchemas(): Schema[] {
        return this.itemSchemas;
    }

    tryValidateAndFillDefaults(val: any, opts?: ValidationOpts): ExtractType<TItemSchema> | null {
        if (this.required && val === undefined) {
            return this.getDefault()!;
        }

        if (val === undefined && !this.required) {
            return null;
        }

        const validationError = this.validate(val, opts);
        if (!validationError) {
            return null;
        }

        const errors: string[] = [];
        for (const itemSchema of this.itemSchemas) {
            try {
                return itemSchema.tryValidateAndFillDefaults(val, opts);
            } catch (e: any) {
                errors.push(e);
            }
        }

        throw new Error('Could not validate union: ' + errors.join(','));
    }

    serialize() {
        return { type: 'union', optional: !this.required, variants: this.itemSchemas.map((item) => item.serialize()) };
    }

    toDecoder(): d.Decoder<ExtractType<TItemSchema>> {
        type Result = ExtractType<TItemSchema>;
        const decoder: d.Decoder<Result> = [...this.itemSchemas]
            .reverse()
            .reduce((tail, schema) => d.alt(schema.toDecoder(), tail), d.empty as d.Decoder<Result>);
        return extendFromSchema(this, decoder);
    }
}

export class UnknownSchema extends Schema<unknown> {
    _type = 'unknown' as const;

    constructor() {
        super();
        return this.optional();
    }

    // Validates anything.
    validateType(val: any): string | null {
        return null;
    }

    serialize() {
        return { type: 'unknown', optional: !this.required };
    }

    toDecoder(): d.Decoder<unknown> {
        return d.map(d.any, deepCopy);
    }
}

//

// Module exports
// eslint-disable-next-line id-blacklist
export function string(): StringSchema {
    return new StringSchema();
}

// eslint-disable-next-line id-blacklist
export function number(): NumberSchema {
    return new NumberSchema();
}

export function float(): NumberSchema {
    return new NumberSchema();
}

export function int(): NumberSchema {
    return new IntegerSchema();
}

// eslint-disable-next-line id-blacklist
export function boolean(): BooleanSchema {
    return new BooleanSchema();
}

export function union<TItemSchema extends Schema>(itemSchemas: TItemSchema[]): UnionSchema<TItemSchema> {
    return new UnionSchema(itemSchemas);
}

export function object<T extends { [k: string]: Value }, Value extends Schema = any>(keys: T) {
    return new ObjectSchema<T>(keys);
}

export function array<TItem>(itemSchema: Optional<TItem>): ArraySchema<TItem | undefined | null>;
export function array<TItemSchema extends Schema>(itemSchema: TItemSchema): ArraySchema<ExtractType<TItemSchema>>;
export function array(itemSchema: any): ArraySchema<any> {
    return new ArraySchema(itemSchema);
}

export function map<TItemSchema>(itemSchema: Schema<TItemSchema>): Schema<{ [k: string]: TItemSchema }> {
    return new MapSchema(itemSchema);
}

export function tuple<T extends string>(values: [T, ...T[]] | readonly [T, ...T[]]) {
    return new TupleSchema(values as [T, ...T[]]);
}

export function unknown() {
    return new UnknownSchema();
}

/** Special combinator which _must_ exit early in the case of `undefined` due to how SB is expected to behave. */
const extendFromSchema = <A>(schema: Schema<A>, decoder: d.Decoder<A>) => {
    // if there is custom validation then sequence it to run on the result of the decoder
    const customValidatorFn: (value: A) => string | null = (schema as any).customValidatorFn; // unsafeness
    if (customValidatorFn) {
        decoder = d.bind(decoder, (input) => {
            const errorMsg = customValidatorFn(input);
            return errorMsg ? d.fail(errorMsg) : d.pure(input);
        });
    }

    return d.wrap<d.Decoder<A>>((input) => {
        // if the input is undefined bail immediately with a default value
        if (typeof input === 'undefined') return d.Ok(schema.getDefault() as A);

        // finally, run it all
        return d.decode(decoder, input);
    });
};
