import { i18n } from '@play-co/astro';
import { Container, ITextStyle } from 'pixi.js';

import { MarkupRenderer } from '../../../../lib/pixi/tools/MarkupRenderer';
import { languageFontMap, systemFontMap, TextLanguageId } from '../../../defs/text';

// types
//-----------------------------------------------------------------------------
// public
export type MarkupTextOptions = {
    // markup formatted text
    text?: string;
    // text style
    style: Partial<ITextStyle>;
    // use given text directly instead of parsing it
    literal?: boolean; // default: false
    // use system font
    system?: boolean; // default: false

    center?: boolean;
};

/*
    markup text
*/
export class MarkupText extends Container {
    // fields
    //-------------------------------------------------------------------------
    private _format: string;
    private _style: Partial<ITextStyle>;
    private _literal: boolean;
    private _center: boolean;

    // properties
    //-------------------------------------------------------------------------
    // text
    public get text(): string {
        return this._format;
    }

    public set text(format: string) {
        // set format
        this._format = format;

        // update view
        this._update();
    }

    // api
    //-------------------------------------------------------------------------
    constructor(options: MarkupTextOptions) {
        super();

        // add font family if missing
        if (options.style.fontFamily) this._style = options.style;
        else {
            const language = i18n.language as TextLanguageId;
            this._style = {
                ...options.style,
                fontFamily: options.system ? systemFontMap[language] : languageFontMap[language].fontName,
            };
        }

        // set fields
        this._literal = !!options.literal;
        this._center = !!options.center;

        // initial update
        this.text = options.text || '';
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _update() {
        // clear previous content
        this.removeChildren();

        // re-render
        new MarkupRenderer({
            format: this._format,
            style: this._style,
            literal: this._literal,
            center: this._center,
        })
            .build()
            .forEach((content) => this.addChild(content));
    }
}
