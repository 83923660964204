import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { ILoader } from '../../../plugins/nav/ILoader';
import { LayoutScreen3 } from '../../lib/screens/LayoutScreen3';
import { LoadingProgessBar } from '../../lib/ui/progress/LoadingProgessBar';
import { InstallView } from '../pet/InstallView';

/*
    pwa: install info loader
*/
export class InstallLoader extends LayoutScreen3 implements ILoader {
    // fields
    //-------------------------------------------------------------------------
    private _view: InstallView;

    // fields
    //-------------------------------------------------------------------------
    private _progress: LoadingProgessBar;

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...InstallView.assets()]);
    }

    public async init() {
        console.log('LOADER: Install');

        // spawn scene
        this._view = this.root.addChild(new InstallView(true));
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        this._view.resize(size);
    }
}
