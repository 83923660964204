import { Container } from 'pixi.js';

import { pwaIsSelfInstalled } from '../../app/world/pet/SubscribeButtonPopup';
import { SizeType } from '../../lib/defs/types';
import { Mutex } from '../../lib/pattern/Mutex';
import { sleep } from '../../replicant/util/jsTools';
import { ILoader } from './ILoader';

// settings
//-----------------------------------------------------------------------------
const throttleDelay = 0.3;

// types
//-----------------------------------------------------------------------------
// public
export type LoaderDef = new () => ILoader;
export type LoaderId = 'boot' | 'default' | 'splash' | string;
export type LoaderDefs = Record<LoaderId, LoaderDef>;

export interface LoaderManagerOptions {
    root: Container;
    defs: LoaderDefs;
}

export interface ManualLoader {
    start: () => Promise<void>;
    stop: () => Promise<void>;
}

// private
type LoaderMap = Record<LoaderId, ILoader>;

/*
    nav loader manager
*/
export class LoaderManager {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _defs: LoaderDefs;
    // scene
    private _root: Container;
    // state
    private _loaders: LoaderMap = {};
    private _activeLoader?: ILoader;
    private _mutex = new Mutex();
    private _size: SizeType;

    // init
    //-------------------------------------------------------------------------
    constructor(options: LoaderManagerOptions) {
        // set fields
        this._root = options.root;
        this._defs = options.defs;
    }

    // api
    //-------------------------------------------------------------------------
    public async init() {
        // init splash loader
        await this._initSplash();

        // init remaining loaders
        await this._initLoaders();
    }

    public async run(loaderId: LoaderId, promises: Promise<any>[], throttle?: boolean) {
        const loader = this._loaders[loaderId];

        // lock
        await this._mutex.lock();

        // register progress with each promise
        let loaded = 0;
        promises.forEach((promise) =>
            promise.then(() => {
                ++loaded;
                loader.progress?.(loaded / promises.length);
            }),
        );

        // throttle with a short delay
        if (throttle) {
            //TODO: this may cause a frame skip
            await sleep(0);
            if (loaded < promises.length) await sleep(throttleDelay);
        }

        // if still loading, show load screen
        if (loaded < promises.length) {
            // start loader
            console.log('START!', loaderId);
            await this._loaderStart(loader);

            // wait for all to complete
            await Promise.all(promises);

            // stop loader
            await this._loaderStop();
        }

        // unlock
        this._mutex.unlock();
    }

    public runManual(loaderId: LoaderId): ManualLoader {
        const loader = this._loaders[loaderId];

        // return interface to control this loader instance
        return {
            start: async () => {
                // lock
                await this._mutex.lock();

                // start loader
                this._loaderStart(loader);
            },
            stop: async () => {
                // stop loader
                this._loaderStop();

                // unlock
                this._mutex.unlock();
            },
        };
    }

    public step(dt: number): void {
        // step active loader
        this._activeLoader?.step?.(dt);
    }

    public resized(size: SizeType): void {
        // update fields
        this._size = size;

        // notify active loader
        this._activeLoader?.resized?.(size);
    }

    // private: init
    //-------------------------------------------------------------------------
    private async _initSplash() {
        // if splash defined
        if (this._defs.splash) {
            // initialize loader
            const loader = await this._initLoader('splash');

            // start loader
            await this._loaderStart(loader);
        }
    }

    private async _initLoaders() {
        // collect loader inits. except splash
        // prettier-ignore
        const loaderPromises = Object.keys(this._defs).map((loaderId) => (loaderId === 'splash' ? undefined : this._initLoader(loaderId)));

        // await all initialized
        await Promise.all(loaderPromises);
    }

    private async _initLoader(loaderId: LoaderId) {
        // create loader
        const loader = (this._loaders[loaderId] = new this._defs[loaderId]());

        // preload
        await Promise.all(loader?.preload());

        // initialize
        await loader.init();

        return loader;
    }

    // private: loader control
    //-------------------------------------------------------------------------
    public async _loaderStart(loader: ILoader): Promise<void> {
        // stop last loader. this only applies to splash
        this._loaderStop();

        // handle spawning
        await loader.spawning?.();

        // handle current size
        loader.resized?.(this._size);

        // add to render scene
        this._root.addChild(loader.root);

        // set active loader
        this._activeLoader = loader;

        // handle spawned
        await loader.spawned?.();
    }

    public async _loaderStop(): Promise<void> {
        const loader = this._activeLoader;
        if (loader) {
            // handle despawning
            await loader.despawning?.();

            if (pwaIsSelfInstalled()) {
                // remove from render scene
                this._root.removeChild(loader.root);

                // handle despawned
                loader.despawned?.();
            } else {
                //TODO: crappy flicker quickfix
                sleep(0.6).then(() => {
                    this._root.removeChild(loader.root);
                    loader.despawned?.();
                });
            }

            // reset active loader
            this._activeLoader = undefined;
        }
    }
}
