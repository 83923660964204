import type { OTPVerificationOpts } from '../common/OTP';
import { Replicant, ReplicantConfig, validateConfig } from '../ReplicantConfig';
import { generateRandomId } from '../utils/Utils';
import ClientInternalKeyValueStore from './ClientInternalKeyValueStore';
import ClientKeyValueStore from './ClientKeyValueStore';
import { ClientReplicant, ClientReplicantDevOpts, InternalReplicantOptions } from './ClientReplicant';
import { loginOrCreateUser } from './LoginOrCreateUser';
import ReplicantHttpClient from './ReplicantHttpClient';

// prettier-ignore
/** `onLoginActionArgs` is required if `config.onLoginAction` is defined and points to an action that takes arguments  */
export type OnLoginActionArgs<T extends Replicant> =
    T['onLoginAction'] extends never ? {} :
    Parameters<T['actions'][T['onLoginAction']]['fn']>[1] extends void ? {} :
    { onLoginActionArgs: Parameters<T['actions'][T['onLoginAction']]['fn']>[1] };

export type CommonReplicantOpts = {
    kvStore?: { prefetchKeys?: string[]; prefetchInternalKeys?: string[] };
    debug?: boolean;
    devOpts?: ClientReplicantDevOpts;
};

export async function createReplicant<T extends Replicant>(opts: {
    clientAppName?: string;
    config: ReplicantConfig<T>;
    id?: string;
    options: InternalReplicantOptions;
    otp?: OTPVerificationOpts;
    keyValueStorePrefetchKeys: string[] | undefined;
    internalKeyValueStorePrefetchKeys?: string[];
    devOpts?: ClientReplicantDevOpts;
}): Promise<{ replicant: ClientReplicant<T>; webPlayerAuthToken?: string }> {
    const { config, id, options, internalKeyValueStorePrefetchKeys, keyValueStorePrefetchKeys } = opts;

    // Validate config.
    validateConfig(config);

    if (!id && !options.isWebPlayable) {
        throw new Error('Invalid ID');
    }

    // eslint-disable-next-line prefer-const
    let replicant: ClientReplicant<T> | undefined;

    const now = () => replicant?.now() || options.devOpts?.dateNow?.() || Date.now();

    const httpClient = new ReplicantHttpClient<T>({
        id,
        isWebPlayable: options.isWebPlayable,
        endpoint: options.endpoint,
        version: config.version,
        fetchOverride: options.fetchOverride,
        signature: options.signature,
        retryOptions: options.devOpts?.retryOptions || {
            retries: 1,
            linearBackoff: 500,
        },

        obtainSignature: options.obtainSignature,
        now,
    });

    // Random session identifier.
    const sessionId = generateRandomId();

    const loginResult = await loginOrCreateUser<T>({
        clientAppName: opts.clientAppName,
        httpClient,
        devOpts: options.devOpts,
        isWebPlayable: options.isWebPlayable,
        loginToken: options.loginToken,
        onLoginActionArgs: options.onLoginActionArgs,
        otp: options.isWebPlayable ? opts.otp : undefined,
        prefetchKeys: keyValueStorePrefetchKeys,
        prefetchInternalKeys: internalKeyValueStorePrefetchKeys,
        sessionId,
    }).catch((error) => {
        config.onError?.(error);

        throw error;
    });

    const clockOffset = loginResult.clockOffset;
    options.clockOffset = clockOffset;

    // Validate state
    try {
        loginResult.data.state = config.stateSchema.tryDecode(loginResult.data.state);
    } catch (err: any) {
        throw new Error('User state invalid: ' + err.message);
    }

    const {
        abTestsDynamicConfig,
        extraData,
        prefetchedKeys,
        prefetchedInternalKeys,
        userAssetsBaseUrl,
        chatbotAssetUrls,
        abTestChangeEvents,
        ...userData
    } = loginResult.data;

    const kvStore = new ClientKeyValueStore(httpClient, prefetchedKeys);
    const internalKvStore = new ClientInternalKeyValueStore(httpClient, prefetchedInternalKeys);

    replicant = new ClientReplicant(
        'id' in loginResult.data ? loginResult.data.id : id!,
        sessionId,
        userData,
        config,
        options,
        httpClient,
        kvStore,
        internalKvStore,
        userAssetsBaseUrl,
        chatbotAssetUrls as { [assetName in T['chatbotAssets']]: string },
        extraData,
        abTestChangeEvents,
        abTestsDynamicConfig,
    );

    const webPlayerAuthToken =
        'webPlayerAuthToken' in loginResult.data ? loginResult.data.webPlayerAuthToken : undefined;

    return { replicant, webPlayerAuthToken };
}
