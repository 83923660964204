import { teaHash } from '@play-co/replicant';

import { ABTestBucket, AbTestId } from '../components/abtests';
import { AsyncActionAPI, GettersAPI, MutableState, State } from '../defs/replicant';
import { abTests } from '../ruleset';

// replicant api wrappers
//-----------------------------------------------------------------------------
export async function replicantGetPlayerStates(
    api: AsyncActionAPI | GettersAPI,
    ids: string[],
): Promise<Record<string, MutableState>> {
    const states: Record<string, MutableState> = {};

    // collect legit states from given ids
    const realStates = await api.fetchStates(ids);
    for (const [id, entry] of Object.entries(realStates)) {
        states[id] = entry.state;
    }

    return states;
}

export function getDynamicReceiverBucket<T extends AbTestId>(userId: string, testId: T): ABTestBucket<T> {
    const buckets = abTests[testId].buckets;

    const roll = teaHash(`${userId}_${testId}`, 0);
    const index = Math.floor(roll * buckets.length);

    return buckets[index].id;
}

// ab test query from state
export function getAbTest<T extends AbTestId>(state: State, testId: T): ABTestBucket<T> {
    return (state.ruleset.abTests as any)[testId]?.bucketId;
}
