import { action, asyncAction, SB } from '@play-co/replicant';

import core from '../defs/core';
import { AsyncActionAPI, MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import { stateSchema } from '../state';
import { timeFromComponents } from '../util/timeTools';
import { onPetsExit, onPetsInit } from './pets';

// TODO: Better naming, the state had to be split out in a separate file to allow game to launch

// hooks
//-----------------------------------------------------------------------------
async function hookInit(api: AsyncActionAPI, state: MutableState, entryData: any) {
    // notify listeners
    onPetsInit(api, state);
}

// actions
//-----------------------------------------------------------------------------
export const coreActions = {
    // to be called once at client init to do init things
    coreInit: asyncAction(
        async (state: MutableState, options: { entryData: any; url: string }, api: AsyncActionAPI) => {
            // Validate and set chatbot metadata
            if (!api.chatbot.appleDeviceTokenIsValid() || !api.chatbot.androidDeviceTokenIsValid()) {
                api.chatbot.validateSubscription();
            }

            /*
        // dev only
        if (process.env.IS_DEVELOPMENT) {
            // override with mock state
            Object.assign(state, debugStates[api.getUserID()]);
        }

        // update state
        state.enterTime = api.date.now();

        */
            // update stats
            ++state.enterCount;
            state.pwaUrl = options.url;

            // hook init
            await hookInit(api, state, options.entryData);
        },
    ),

    // idle step to be called in an interval by the client.
    coreIdleStep: action((state: MutableState, options: { interval: number; home: boolean }, api: SyncActionAPI) => {
        // step idle exit
        stepIdleExit(api, options.interval, options.home);
    }),

    // reset all state
    coreReset: asyncAction(async (state: MutableState, _, api: AsyncActionAPI) => {
        if (process.env.IS_DEVELOPMENT || process.env.LINE_GUEST) {
            // set default state
            Object.assign(state, stateSchema.getDefault());
        }
    }),

    triggerCooldown: action((state: MutableState, args: { id: string }, api: SyncActionAPI) => {
        state.cooldowns[args.id] = {
            startTimestamp: api.date.now(),
        };
    }),
};

// scheduled actions
//-----------------------------------------------------------------------------
export const coreScheduledActionsSchema = {
    coreExitEvent: SB.object({ home: SB.boolean() }),
};
export const coreScheduledActions = {
    // app exit event
    coreExitEvent: (state: MutableState, args: { home: boolean }, api: ScheduledActionAPI) => {
        // notify listeners
        onPetsExit(api, state, args.home);
    },
};

// api
//-----------------------------------------------------------------------------
export function isUserInactive(api: SyncActionAPI | ScheduledActionAPI, state: State | MutableState) {
    const now = api.date.now();
    const lastUpdateTime = state.updatedAt;

    if (now - lastUpdateTime > core.inactiveTime) return true;

    return false;
}

export function coreIsNewPlayer(state: State) {
    return state.enterCount <= 1;
}

// private: steppers
//-----------------------------------------------------------------------------
function stepIdleExit(api: SyncActionAPI, interval: number, home: boolean) {
    // (re)schedule exit event such that its fired only when this step stops stepping
    api.scheduledActions.schedule.coreExitEvent({
        args: { home },
        notificationId: 'exitEvent',
        delayInMS: interval + timeFromComponents({ seconds: 10 }),
    });
}
