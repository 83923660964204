import { action, asyncAction, WebPushSubscription } from '@play-co/replicant';

import { chatbotMessageTemplates } from '../chatbot/messageTemplates';
import { MutableState, SyncActionAPI } from '../defs/replicant';

// actions
//-----------------------------------------------------------------------------
export const pwaActions = {
    // subscribe webpush
    pwaSubscribe: asyncAction(
        async (state: MutableState, options: { subscription: WebPushSubscription }, api: SyncActionAPI) => {
            api.chatbot.setWebPushSubscription(options.subscription);
        },
    ),

    // unsubscribe webpush
    pwaUnsubscribe: action((state, args, api) => {
        api.chatbot.setWebPushSubscription(undefined);
    }),

    // send notification
    pwaSend: asyncAction(
        async (state: MutableState, options: { uid: string; message: string; url: string }, api: SyncActionAPI) => {
            api.chatbot.sendMessage(
                options.uid,
                chatbotMessageTemplates.dailyNotification({
                    args: {
                        imageKey: 'firstSession0',
                        text: options.message,
                        url: options.url,
                    },
                    payload: { $channel: 'WEBPUSH', feature: 'daily_notification', $subFeature: null },
                }),
            );
        },
    ),

    // test state
    pwaStateInc: asyncAction(async (state: MutableState, _, api: SyncActionAPI) => {
        ++state.pwaTest1;
    }),
};
