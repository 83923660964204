import { LoaderDefs } from '../../plugins/nav/LoaderManager';
import { ScreenDefs } from '../../plugins/nav/NavPlugin';
import { AlphaTransition } from '../../plugins/nav/transitions/AlphaTransition';
import { AlertPopup } from '../lib/ui/popups/AlertPopup';
import { InfoPopup } from '../lib/ui/popups/InfoPopup';
import { PetHelpPopup } from '../lib/ui/popups/PetHelpPopup';
import { PromptPopup } from '../lib/ui/popups/PromptPopup';
import { CheatMenu } from '../world/cheat/CheatMenu';
import { CheatScreen } from '../world/cheat/CheatScreen';
import { InstallLoader } from '../world/loaders/InstallLoader';
import { MajorLoader } from '../world/loaders/MajorLoader';
import { SpinnerLoader } from '../world/loaders/SpinnerLoader';
import { SplashLoader } from '../world/loaders/SplashLoader';
import { AddHomePopup1 } from '../world/pet/AddHomePopup1';
import { AddHomePopup2 } from '../world/pet/AddHomePopup2';
import { AddHomePopup2Android } from '../world/pet/AddHomePopup2Android';
import { InstallScreen } from '../world/pet/InstallScreen';
import { OpenHomePopup } from '../world/pet/OpenHomePopup';
import { PetNamePopup } from '../world/pet/PetNamePopup';
import { PetScreen } from '../world/pet/PetScreen';
import { pwaIsSelfInstalled, SubscribeButtonPopup } from '../world/pet/SubscribeButtonPopup';
import { SettingsPopup } from '../world/settings/SettingsPopup';
import { TipScreen } from '../world/tip/TipScreen';

// config
//-----------------------------------------------------------------------------
export enum NavLayer {
    main,
    menu,
    popup,
    tip,
    cheat,
    cheatPopup,
    high,
    higher,
    highest,
    count,
}

export const navLoaders: LoaderDefs = {
    splash: pwaIsSelfInstalled() ? SplashLoader : InstallLoader,
    boot: pwaIsSelfInstalled() ? MajorLoader : InstallLoader,
    default: SpinnerLoader,
};

// screens
//-----------------------------------------------------------------------------
export const navScreens: ScreenDefs = {
    addHomePopup1: {
        screen: AddHomePopup1,
        layer: NavLayer.high,
    },
    addHomePopup2: {
        screen: AddHomePopup2,
        layer: NavLayer.high,
    },
    addHomePopup2Android: {
        screen: AddHomePopup2Android,
        layer: NavLayer.highest,
    },
    alertPopup: {
        screen: AlertPopup,
        layer: NavLayer.popup,
    },
    cheatScreen: {
        screen: CheatScreen,
        layer: NavLayer.cheat,
    },
    cheatPopup: {
        screen: CheatMenu,
        layer: NavLayer.cheatPopup,
    },
    infoPopup: {
        screen: InfoPopup,
        layer: NavLayer.popup,
    },
    installScreen: {
        screen: InstallScreen,
        layer: NavLayer.main,
        loader: 'splash',
    },
    openHomePopup: {
        screen: OpenHomePopup,
        layer: NavLayer.highest,
    },
    petHelpPopup: {
        screen: PetHelpPopup,
        layer: NavLayer.popup,
    },
    petScreen: {
        screen: PetScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    petNamePopup: {
        screen: PetNamePopup,
        layer: NavLayer.popup,
    },
    promptPopup: {
        screen: PromptPopup,
        layer: NavLayer.popup,
    },
    settingsPopup: {
        screen: SettingsPopup,
        layer: NavLayer.popup,
    },
    subscribeButtonPopup: {
        screen: SubscribeButtonPopup,
        layer: NavLayer.higher,
    },
    tipScreen: {
        screen: TipScreen,
        layer: NavLayer.tip,
    },
};
