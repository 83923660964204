import { Container, Graphics, Sprite, Texture } from 'pixi.js';

import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { uiAlignCenter, uiAlignCenterX, uiScaleToWidth } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { MarkupText } from '../../lib/ui/text/MarkupText';

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    icon: 'install-icon.png',
    //icon: 'install-icon-basic.png',
};

/*
 */
export class InstallView extends Container {
    // fields
    //-------------------------------------------------------------------------
    private _bg: Sprite;
    private _panel: Graphics;
    private _ja: boolean;

    // init
    //-------------------------------------------------------------------------
    constructor(forceJa: boolean) {
        super();

        this._ja = forceJa;

        // bg
        this._bg = this.addChild(
            Sprite.from(Texture.WHITE).props({
                tint: 0xd0d0d0,
            }),
        );
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    public resize(size: SizeType): void {
        this._bg.width = size.width;
        this._bg.height = size.height;

        this._panel?.removeSelf();
        this._spawnPanel(size);
    }

    //-------------------------------------------------------------------------
    private _spawnPanel(size: SizeType): void {
        const ja_pwaInstallInfoAndroid =
            'ゲームをインストールするには、\nこのサイトをホームへ追加して下さい。\n\nChromeブラウザの「︙」をタップし\n開いたメニューのなかから\n<style fontWeight="bold">アプリをインストール」</>を選択して下さい。\n完了しましたら、ホーム画面から\n   精霊とボク。をタップして\nゲームをお楽しみ下さい。';
        const ja_pwaInstallInfoIos =
            'ゲームをインストールするには、\nこのサイトをホームへ追加して下さい。\n\nSafariブラウザのメニューから、\n共有アイコンをタップし、\n<style fontWeight="bold">ホーム画面に追加」</>を選択して下さい。\n完了しましたら、ホーム画面から\n精霊とボク。をタップして\nゲームをお楽しみ下さい。';
        const ja_pwaInstallAddHome = 'ホーム画面へ追加';

        this._panel = this.addChild(
            new Graphics().beginFill(0xffffff, 1).drawRoundedRect(0, 0, 680, 1056, 40).endFill(),
        );

        // icon
        const icon = this._panel.addChild(Sprite.from(manifest.icon));
        icon.y = 64;
        icon.scale.set(0.8);
        uiAlignCenterX(this._panel, icon, -10);

        // header
        const header = this._panel.addChild(
            new BasicText({
                text: this._ja ? ja_pwaInstallAddHome : '[pwaInstallAddHome]',
                style: {
                    fill: 0,
                    fontSize: 44,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                },
            }),
        );
        header.y = 474;
        uiAlignCenterX(this._panel, header);

        // info
        const info = this._panel.addChild(
            new MarkupText({
                center: true,
                text: this._ja
                    ? app.platform.ios
                        ? ja_pwaInstallInfoIos
                        : ja_pwaInstallInfoAndroid
                    : app.platform.ios
                    ? '[pwaInstallInfoIos]'
                    : '[pwaInstallInfoAndroid]',
                style: {
                    align: 'center',
                    fill: 0,
                    fontSize: 30,
                    lineJoin: 'round',
                    padding: 6,
                    leading: 5,
                },
            }),
        );
        info.y = 568;
        uiAlignCenterX(this._panel, info);

        uiScaleToWidth(this._panel, size.width - 70);
        uiAlignCenter(this, this._panel);

        if (!app.platform.ios) this._panel.y += 50;
    }
}
