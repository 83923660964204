import { Layout } from '@pixi/layout';
import { ResizePlugin } from '@play-co/astro';
import { Container } from 'pixi.js';

import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { IScreen } from '../../../plugins/nav/IScreen';
import { pixiConfig } from '../../defs/config';

/*
    base layout screen. normalizes screen size.
*/

export class LayoutScreen3 implements IScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _root: Container;
    private _base: Layout;
    public size: SizeType;

    // properties
    //-------------------------------------------------------------------------
    public get root(): Container {
        return this._root;
    }

    public get base(): Layout {
        return this._base;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        // root
        this._root = new Container();

        // base
        this._base = this._root.addChild(
            new Layout({
                id: 'root',
                styles: {
                    width: '100%',
                    height: '100%',
                },
            }),
        );
    }

    // api
    //-------------------------------------------------------------------------
    protected empty() {
        // remove all children
        this.base.removeChildren();

        // FIXME workaround to remove all children
        for (const id of this.base.content.children.keys()) {
            this.base.removeChildByID(id);
        }
    }

    // impl
    //-------------------------------------------------------------------------
    public resized(size: SizeType): void {
        const appSize = pixiConfig.size;
        const resizer: ResizePlugin = app.get(ResizePlugin);

        size.width = resizer.w;
        size.height = resizer.h;

        this._root.scale.set(size.height / appSize.height);
        size.width = Math.round((size.width * appSize.height) / size.height);
        size.height = appSize.height;

        this.size = size;

        // layout resize
        this._base.resize(size.width, size.height);
    }
}
