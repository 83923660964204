/** Used to hide runtime implementations behind an opaque type. Conversion between the two is handled by `wrap` and `unwrap`. */
interface Newtype<URI, Rep> {
    _URI: URI;
    _Rep: Rep;
}

/** Used to coerce a runtime representation into an opaque type. */
export function wrap<T extends Newtype<any, any>>(value: T['_Rep']): T {
    return value as T;
}

/** Used to coerce an opaque type back to it's runtime representation. */
export function unwrap<T extends Newtype<any, any>>(value: T): T['_Rep'] {
    return value as T['_Rep'];
}

/** Represents the result of a decoding operation, either `Ok` with a successfully decoded value or `Err` with a list of errors. */
export type Result<E, A> = ['Err', E] | ['Ok', A];

export function isErr<E, A>(x: Result<E, A>): x is ['Err', E] {
    return x[0] === 'Err';
}

export function isOk<E, A>(x: Result<E, A>): x is ['Ok', A] {
    return x[0] === 'Ok';
}

/** Construct a success result with a value. */
export const Ok = <A, E = never>(value: A): Result<E, A> => ['Ok', value];

/** Construct a failed result with a error. */
export const Err = <E, A = never>(error: E): Result<E, A> => ['Err', error];

export type DecodeError =
    | ['DecodeError', string]
    | ['TypeMismatch', string, string]
    | ['ErrorAtIndex', number, DecodeError]
    | ['ErrorAtProperty', string, DecodeError];

export const DecodeError = (message: string): DecodeError => ['DecodeError', message];

export const TypeMismatch = (expected: string, found: string): DecodeError => ['TypeMismatch', expected, found];

export const ErrorAtIndex = (index: number, error: DecodeError): DecodeError => ['ErrorAtIndex', index, error];

export const ErrorAtProperty = (property: string, error: DecodeError): DecodeError => [
    'ErrorAtProperty',
    property,
    error,
];

export type MultipleErrors = DecodeError[];

/**
 * The `Decoder` type is the foundation of this small library, and the implementation details are hidden behind this interface.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Decoder<A>
    extends Newtype<{ readonly Decoder: unique symbol }, (input: unknown) => Result<MultipleErrors, A>> {}

export type DecoderRecord<T extends Record<string, any>> = {
    [K in keyof T]: Decoder<T[K]>;
};
