import { ErrorAtIndex, ErrorAtProperty } from '.';
import * as d from './decoders';
import {
    Decoder,
    Err,
    Ok,
    isOk,
    unwrap,
    wrap,
    isErr,
    MultipleErrors,
    Result,
    DecodeError,
    DecoderRecord,
    TypeMismatch,
} from './types';

/**
 * Transform the result of a decoder.
 *
 * ```ts
 * const decoder = d.map(d.string, (result) => result.length);
 * d.decode(decoder, "Hi there!"); // Ok(9)
 * ```
 * */
const mapCombinator = <A, B>(decoder: Decoder<A>, f: (a: A) => B) =>
    wrap<Decoder<B>>((input) => {
        const result = unwrap(decoder)(input);
        if (isErr(result)) return result;
        return Ok(f(result[1]));
    });

/**
 * Adds a fallback decoder.
 *
 * ```ts
 * const decoder =
 *   d.alt(
 *     d.record({ name: d.string ),
 *     d.pure({ name: '' })
 *   );
 * d.decode(decoder, null); // Ok({ name: '' })
 * ```
 */
const alternativeCombinator = <A, B>(first: Decoder<A>, second: Decoder<B>) =>
    wrap<Decoder<A | B>>((input) => {
        const firstResult = unwrap(first)(input);
        if (isOk(firstResult)) return firstResult;
        const secondResult = unwrap(second)(input);
        if (isOk(secondResult)) return secondResult;
        return Err(firstResult[1].concat(secondResult[1]));
    });

/**
 * For sequencing decoders. This allows using the result of a decoder to build the next decoder.
 *
 * ```ts
 * const primeDecoder = d.bind(d.int, (n) => isPrime(n) ? d.pure(n) : d.fail('Not a prime number'));
 * d.decode(primeDecoder, 10); // Err('Not a prime number')
 * d.decode(primeDecoder, 11); // Ok(11)
 * ```
 *
 * This can also be used for partial/staggered decoding.
 *
 * ```ts
 * const documentDecoder =
 *   d.bind(
 *     d.record({ version: d.int }),
 *     ({ version }) => {
 *       switch (version) {
 *         case 5:
 *           return documentDecoderV5;
 *         case 4:
 *           return documentDecoderV4;
 *         default:
 *           return d.fail('Document version is not supported');
 *       }
 *     }
 *   );
 * d.decode(documentDecoder, { version: 1, ...restOfDocument }); // Err('Document version is not supported')
 * ```
 */
const bindCombinator = <A, B>(decoder: Decoder<A>, f: (a: A) => Decoder<B>) =>
    wrap<Decoder<B>>((input) => {
        const result = unwrap(decoder)(input);
        if (isErr(result)) return result;
        const next = f(result[1]);
        return unwrap(next)(input);
    });

/** Lifts a decoder of things into a decoder of arrays of things. */
const arrayCombinator = <A>(decoder: Decoder<A>) =>
    wrap<Decoder<A[]>>((input) => {
        if (!Array.isArray(input)) return Err([TypeMismatch('array', typeof input)]);
        const decode = unwrap(decoder);
        const result: A[] = new Array(input.length);
        const errors: MultipleErrors = [];
        let item: Result<MultipleErrors, A>;
        for (let i = 0; i < input.length; i++) {
            item = decode(input[i]);
            if (isOk(item)) result[i] = item[1];
            else errors.push(...item[1].map((error) => ErrorAtIndex(i, error)));
        }
        if (errors.length) return Err(errors);
        return Ok(result);
    });

/** Lifts a decoder of things into a decoder of maps from strings to things. */
const stringMapCombinator = <A>(decoder: Decoder<A>) =>
    wrap<Decoder<Record<string, A>>>((input) => {
        if (typeof input !== 'object') return Err([TypeMismatch('object', typeof input)]);
        if (input === null) return Err([TypeMismatch('object', 'null')]);
        const object = input as Record<string, unknown>;
        const decodeEntry = unwrap(decoder);
        const output: Record<string, A> = {};
        const errors: MultipleErrors = [];
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                const result = decodeEntry(object[key]);
                if (isOk(result)) output[key] = result[1];
                else {
                    errors.push(...result[1].map<DecodeError>((error) => ErrorAtProperty(key, error)));
                }
            }
        }
        return errors.length ? Err(errors) : Ok(output);
    });

/** Lifts a record of decoders into a decoder of records. */
const recordCombinator = <A extends Record<string, any>>(decoders: DecoderRecord<A>) =>
    wrap<Decoder<A>>((input) => {
        if (typeof input !== 'object') return Err([TypeMismatch('object', typeof input)]);
        if (input === null) return Err([TypeMismatch('object', 'null')]);
        const object = input as Record<string, unknown>;
        const output = {} as A;
        const errors: MultipleErrors = [];
        for (const key in decoders) {
            if (Object.prototype.hasOwnProperty.call(decoders, key)) {
                const result = unwrap(decoders[key])(object[key]);
                if (isOk(result)) {
                    if (typeof result[1] !== 'undefined') {
                        output[key] = result[1];
                    }
                } else errors.push(...result[1].map((error) => ErrorAtProperty(key, error)));
            }
        }
        return errors.length ? Err(errors) : Ok(output);
    });

/** For default value handling similar to Zod. */
const defaultCombinator = <A>(value: A, decoder: Decoder<A>) =>
    wrap<Decoder<A>>((input) => {
        // if undefined replace with the default value
        if (typeof input === 'undefined') input = value;
        // then apply the decoder
        return unwrap(decoder)(input);
    });

/** For optional value handling similar to Zod. */
const optionalCombinator = <A>(decoder: Decoder<A>) => alternativeCombinator(decoder, d.undefined);

/** For nullable value handling similar to Zod. */
const nullableCombinator = <A>(decoder: Decoder<A>) => alternativeCombinator(decoder, d.null);

export {
    mapCombinator as map,
    alternativeCombinator as alt,
    bindCombinator as bind,
    arrayCombinator as array,
    stringMapCombinator as stringMap,
    recordCombinator as record,
    defaultCombinator as default,
    optionalCombinator as optional,
    nullableCombinator as nullable,
};
