//TODO: add throttling... or reuse from navplugin/loadermanager

import app from '../../index.entry';
import { ManualLoader } from '../../plugins/nav/LoaderManager';

/*
    manages busy state (spinner)
*/
export class BusyComponent {
    // fields
    //-------------------------------------------------------------------------
    private _count = 0;
    private _active = false;
    private _spinner: ManualLoader = app.nav.loader('default');

    // properties
    //-------------------------------------------------------------------------
    public get count(): number {
        return this._count;
    }

    public set count(count: number) {
        this._count = Math.max(count, 0);
        this._updateBusy();
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _updateBusy() {
        const active = this._count > 0;

        // if active changing
        if (active !== this._active) {
            // update spinner state based on busy state
            if (active) {
                app.input.enabled = false;
                this._spinner.start();
            } else {
                this._spinner.stop();
                app.input.enabled = true;
            }

            // set new active state
            this._active = active;
        }
    }
}
