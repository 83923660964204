import { sleep } from '../../replicant/util/jsTools';
import NakedPromise from '../pattern/NakedPromise';

// state
//-----------------------------------------------------------------------------
let tapped = false;
export const firstTapPromise = new NakedPromise();
export function isTapped() {
    return tapped;
}

// initial input event. useful for playing audio.
//-----------------------------------------------------------------------------
const firstTapListener = () => {
    tapped = true;
    sleep(0.2).then(() => firstTapPromise.resolve());
    window.removeEventListener('pointerdown', firstTapListener);
};
window.addEventListener('pointerdown', firstTapListener);
