import { Decoder, Err, unwrap, wrap, isErr, DecodeError } from './types';

/** Adds a minimum-value constraint to numbers. */
const minCombinator = (min: number, decoder: Decoder<number>) =>
    wrap<Decoder<number>>((input) => {
        const result = unwrap(decoder)(input);
        if (isErr(result)) return result;
        if (result[1] < min) return Err([DecodeError('Unexpected value: expected >= ' + min + ', found ' + result[1])]);
        return result;
    });

/** Adds a maximum-value constraint to numbers. */
const maxCombinator = (max: number, decoder: Decoder<number>) =>
    wrap<Decoder<number>>((input) => {
        const result = unwrap(decoder)(input);
        if (isErr(result)) return result;
        if (result[1] > max) return Err([DecodeError('Unexpected value: expected <= ' + max + ', found ' + result[1])]);
        return result;
    });

/** Adds a minimum-length constraint to values with a `.length` property. */
const minLengthCombinator = <A extends { length: number }>(minLength: number, decoder: Decoder<A>) =>
    wrap<Decoder<A>>((input) => {
        const result = unwrap(decoder)(input);
        if (isErr(result)) return result;
        if (result[1].length < minLength)
            return Err([
                DecodeError('Unexpected value: expected .length >= ' + minLength + ', found ' + result[1].length),
            ]);
        return result;
    });

/** Adds a maximum-length constraint to values with a `.length` property. */
const maxLengthCombinator = <A extends { length: number }>(maxLength: number, decoder: Decoder<A>) =>
    wrap<Decoder<A>>((input) => {
        const result = unwrap(decoder)(input);
        if (isErr(result)) return result;
        if (result[1].length > maxLength)
            return Err([
                DecodeError('Unexpected value: expected .length <= ' + maxLength + ', found ' + result[1].length),
            ]);
        return result;
    });

export {
    minCombinator as min,
    maxCombinator as max,
    minLengthCombinator as minLength,
    maxLengthCombinator as maxLength,
};
