import { Layout } from '@pixi/layout';
import { Container, Graphics } from 'pixi.js';

import { SizeType } from '../../../lib/defs/types';
import { uiCreateMask } from '../../../lib/pixi/uiTools';
import { IScreen } from '../../../plugins/nav/IScreen';
import { pixiConfig } from '../../defs/config';

/*
    base layout screen. normalizes screen size.
*/
export class LayoutScreen2 implements IScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _root: Container;
    private _base: Layout;
    private _mask: Graphics;

    // properties
    //-------------------------------------------------------------------------
    public get root(): Container {
        return this._root;
    }

    public get base(): Layout {
        return this._base;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        const appSize = pixiConfig.size;
        // root
        this._root = new Container();

        // spawn mask
        const mask = (this._mask = uiCreateMask(appSize.width, appSize.height));
        this._root.addChild(mask);
        this._root.mask = mask;

        // base
        this._base = this._root.addChild(
            new Layout({
                id: 'root',
                styles: {
                    // width: '100%',
                    // height: '100%',
                    width: appSize.width,
                    height: appSize.height,
                },
            }),
        );
    }

    // api
    //-------------------------------------------------------------------------
    protected empty() {
        // remove all children
        this.base.removeChildren();

        // FIXME workaround to remove all children
        for (const id of this.base.content.children.keys()) {
            this.base.removeChildByID(id);
        }

        //this._root.removeChildren();
        this._mask.removeSelf();
        this._root.addChild(this._mask);
        this._root.mask = this._mask;
    }

    // impl
    //-------------------------------------------------------------------------
    public resized(size: SizeType): void {
        /*
        //TODO: screen rotation stuff for IOS. WIP. to be moved to seperate plugin with android stuff
        if (size.height < size.width) {
            //this._root.pivot.set(size.width, size.height);
            this._root.rotation = Math.PI / 2;
            this._root.x = size.width;

            // swap width, height
            const t = size.width;
            size.width = size.height;
            size.height = t;

            if (size.width > appSize.width) {
                this._root.scale.set(size.width / appSize.width);
                size.height = Math.round(size.height * appSize.width / size.width);
                size.width = appSize.width;
            }
        } else {
            this._root.scale.set(1);
            this._root.x = 0;
            this._root.rotation = 0;
        }
 
        app.debug(window.orientation);
        //window.screen.orientation || window.screen.mozOrientation || window.screen.msOrientation
        //console.log(window.screen.orientation);
        //*/

        // scale to fit minimum app height
        // if (size.height < appSize.height) {
        //     this._root.scale.set(size.height / appSize.height);
        //     size.width = Math.round((size.width * appSize.height) / size.height);
        //     size.height = appSize.height;
        // } else {
        //     this._root.scale.set(1);
        // }
        //console.log('R', size, app.stage.stage.width, app.stage.stage.height);

        const appSize = pixiConfig.size;

        // layout resize
        if (size.height > appSize.height) {
            this._root.scale.set(1);
            this._root.y = (size.height - appSize.height) / 2;
        } else {
            this._root.scale.set(size.height / appSize.height);
            this._root.x = (appSize.width - this._root.width) / 2;
        }

        this._base.resize(appSize.width, appSize.height);
    }
}
