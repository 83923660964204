import { Container, Graphics, Sprite } from 'pixi.js';

import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { uiAlignCenterX, uiAlignRight, uiCreateQuad, uiScaleToWidth } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { LayoutScreen3 } from '../../lib/screens/LayoutScreen3';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { TextInput } from '../../lib/ui/text/TextInput';

//-----------------------------------------------------------------------------
export type AddHomePopup2AndroidOptions = {
    onConfirm: NakedPromise<string>;
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    close: 'close-x.png',
    arrow: 'up-arrow.png',
    ins: 'android-home-ins.png',
};

/*
    Transparent overlay popup, used for tutorial pet name
*/
export class AddHomePopup2Android extends LayoutScreen3 {
    // fields
    //-------------------------------------------------------------------------
    private _input: TextInput;
    private _confirmButton: ImageButton;
    private _inputContainer: Container;
    private _topFrame: Graphics;
    private _arrow: Sprite;

    // properties
    //-------------------------------------------------------------------------
    public get confirmButton() {
        return this._confirmButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...Object.values(manifest)]);
    }

    public async spawning(options: AddHomePopup2AndroidOptions) {
        // spawn scene
        this._spawn(options);
    }

    public async despawned() {
        this.empty();
        this.root.removeChildren();
        //this._input.stop();
    }

    public async despawning() {
        await this.animateOut();
    }

    public async spawned(): Promise<void> {
        //this._input.start();
        await this.animateIn();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        //uiAlignCenterX(size, this._inputContainer);
        //uiAlignBottom(size, this._topFrame, 200);
        uiScaleToWidth(this._topFrame, size.width * 0.7);
        uiAlignCenterX(size, this._topFrame);
        //uiAlignCenterX(size, this._topFrame);

        uiAlignRight(size, this._arrow, -45);

        this._arrow
            .animate()
            .add(this._arrow, { y: this._arrow.y - 30 }, 0.3, tween.pow2Out)
            .add(this._arrow, { y: this._arrow.y }, 0.3, tween.pow2In)
            .loop();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: AddHomePopup2AndroidOptions) {
        const topFrame = (this._topFrame = uiCreateQuad(0xffffff, 1, 620, 680));
        this.root.addChild(topFrame);

        ////////
        ////////
        ////////
        const ins = topFrame.addChild(Sprite.from(manifest.ins));
        ins.y = 120;
        uiAlignCenterX(topFrame, ins);

        const step1 = ins.addChild(
            new BasicText({
                text: '[pwaStep] 1',
                style: {
                    fill: '#066',
                    fontSize: 22,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                },
            }),
        );
        step1.x = 16;
        step1.y = 12;

        const step2 = ins.addChild(
            new BasicText({
                text: '[pwaStep] 2',
                style: {
                    fill: '#066',
                    fontSize: 22,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                },
            }),
        );
        step2.x = 16;
        step2.y = 11 + 186;

        const step3 = ins.addChild(
            new BasicText({
                text: '[pwaStep] 3',
                style: {
                    fill: '#066',
                    fontSize: 22,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                },
            }),
        );
        step3.x = 16;
        step3.y = 11 + 186 + 186;

        const s2home = ins.addChild(
            new BasicText({
                text: '[pwaAddHome]',
                style: {
                    fill: '#000',
                    fontSize: 28,
                    lineJoin: 'round',
                },
            }),
        );
        s2home.y = 11 + 186 + 66;
        s2home.x = 40;

        // const s3home = ins.addChild(
        //     new BasicText({
        //         text: '[pwaAddHome]',
        //         style: {
        //             fill: '#000',
        //             fontSize: 30,
        //             lineJoin: 'round',
        //             fontWeight: 'bold',
        //         },
        //     }),
        // );
        // s3home.y = 11 + 186 + 186 + 64;
        // uiAlignCenterX(ins, s3home);

        const add = ins.addChild(
            new BasicText({
                text: '[pwaAdd]',
                style: {
                    fill: '#04a',
                    fontSize: 30,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                },
            }),
        );
        add.x = 589 - 134;
        add.y = 448;

        const cancel = ins.addChild(
            new BasicText({
                text: '[pwaCancel]',
                style: {
                    fill: '#04a',
                    fontSize: 30,
                    lineJoin: 'round',
                },
            }),
        );
        cancel.x = 40;
        cancel.y = 448;

        ////////
        ////////
        ////////
        const header = topFrame.addChild(
            new BasicText({
                text: '[pwaAddHome]',
                style: {
                    fill: '#000',
                    fontSize: 34,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                },
            }),
        );
        header.y = 48;
        uiAlignCenterX(topFrame, header);

        ////////
        const close = topFrame.addChild(
            new ImageButton({
                image: manifest.close,
            }),
        );
        close.x = 666 - 130;
        close.y = 37;
        close.onPress = async () => {
            await app.nav.close('addHomePopup2Android');
        };

        ////////
        const arrow = (this._arrow = this.root.addChild(Sprite.from(manifest.arrow)));
        arrow.y = 30;
        const arrowtext = arrow.addChild(
            new BasicText({
                text: '[pwaGo]',
                style: {
                    fill: '#0fc',
                    fontSize: 34,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                },
            }),
        );
        arrowtext.y = 30;
        uiAlignCenterX(arrow, arrowtext);
    }

    private async animateIn() {
        const x = this._topFrame.x;
        this._topFrame.x += 300;
        await this._topFrame.animate().add(this._topFrame, { x: x - 20 }, 0.5, tween.backOut(1.4));
    }

    private async animateOut() {
        await this._topFrame.animate().add(this._topFrame, { x: this._topFrame.x + 620 }, 0.35, tween.backIn(1.4));
    }
}
