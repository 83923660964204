import { TypeMismatch } from '.';
import { DecodeError, Decoder, Err, Ok, wrap } from './types';

/** Lifts a value into a decoder that will succeed. */
const pureDecoder = <A>(value: A) => wrap<Decoder<A>>(() => Ok(value));

/** Creates a decoder that will fail with the supplied message. */
const failDecoder = <A>(message: string) => wrap<Decoder<A>>(() => Err([DecodeError(message)]));

/** The empty decoder which fails with no message. */
const emptyDecoder = wrap<Decoder<never>>(() => Err([]));

/** Matches everything. */
const anyDecoder = wrap<Decoder<unknown>>(Ok);

/** Matches undefined values. */
const undefinedDecoder = wrap<Decoder<undefined>>((input) =>
    typeof input === 'undefined' ? Ok(input) : Err([TypeMismatch('undefined', typeof input)]),
);

/** Matches nulls. */
const nullDecoder = wrap<Decoder<null>>((input) =>
    input === null ? Ok(input) : Err([TypeMismatch('null', typeof input)]),
);

/** Matches boolean values. */
const booleanDecoder = wrap<Decoder<boolean>>((input) =>
    typeof input === 'boolean' ? Ok(input) : Err([TypeMismatch('boolean', typeof input)]),
);

/** Matches string values. */
const stringDecoder = wrap<Decoder<string>>((input) =>
    typeof input === 'string' ? Ok(input) : Err([TypeMismatch('string', typeof input)]),
);

/** Matches all finite numbers. */
const numberDecoder = wrap<Decoder<number>>((input) => {
    if (typeof input !== 'number') return Err([TypeMismatch('number', typeof input)]);
    if (!Number.isFinite(input)) return Err([TypeMismatch('number', input.toString())]);
    return Ok(input);
});

/** Matches all integers. */
const integerDecoder = wrap<Decoder<number>>((input) => {
    if (typeof input !== 'number') return Err([TypeMismatch('integer', typeof input)]);
    if (!Number.isInteger(input)) return Err([TypeMismatch('integer', input.toString())]);
    return Ok(input);
});

export {
    pureDecoder as pure,
    failDecoder as fail,
    emptyDecoder as empty,
    // eslint-disable-next-line id-blacklist
    anyDecoder as any,
    // eslint-disable-next-line id-blacklist
    undefinedDecoder as undefined,
    nullDecoder as null,
    // eslint-disable-next-line id-blacklist
    booleanDecoder as boolean,
    // eslint-disable-next-line id-blacklist
    stringDecoder as string,
    // eslint-disable-next-line id-blacklist
    numberDecoder as number,
    integerDecoder as int,
};
