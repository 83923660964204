import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import { sleep } from '../../../replicant/util/jsTools';
import { LayoutScreen3 } from '../../lib/screens/LayoutScreen3';
import { InstallView } from './InstallView';
import { pwaInstall, pwaOpenInstalled } from './SubscribeButtonPopup';

/*
 */
export class InstallScreen extends LayoutScreen3 {
    // fields
    //-------------------------------------------------------------------------
    private _view: InstallView;

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...InstallView.assets()]);
    }

    public async spawning() {
        // spawn scene
        this._view = this.root.addChild(new InstallView(false));
    }

    public async spawned() {
        sleep(0.1).then(() => {
            pwaOpenInstalled();
            pwaInstall();
        });
    }

    public async despawned() {
        this.empty();
        this.root.removeChildren();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        this._view.resize(size);
    }
}
