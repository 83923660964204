import { Container } from 'pixi.js';

import app from '../../../index.entry';
import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { tween } from '../../../lib/util/tweens';
import { forageItemIds, treatItemIds } from '../../../replicant/defs/items';
import { sleep } from '../../../replicant/util/jsTools';
import { NavLayer } from '../../defs/nav';
import {
    trackTutorialFinish,
    trackTutorialStart,
    trackTutorialStepFinish,
    trackTutorialStepStart,
} from '../../lib/analytics/tutorial';
import { PetHelpPopup } from '../../lib/ui/popups/PetHelpPopup';
import { PetNamePopup } from '../../world/pet/PetNamePopup';
import { PetScreen } from '../../world/pet/PetScreen';

// types
//-----------------------------------------------------------------------------
const id = 'pets';

type StepHandlerOption = {
    stepName: string;
};

const helpMap = {
    treat: {
        title: '[petTreat]',
        items: treatItemIds,
    },
    forage: {
        title: '[petForage]',
        items: forageItemIds,
    },
};
/*
    MVP pets tutorial flow
*/
export class PetTutorialFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    private _petScreen: PetScreen;
    // state
    private _petsTutorialOffset: number[];
    // step map
    private _steps = [
        { name: 'petIntro', handler: this._petIntro, substeps: 4 },
        { name: 'petName', handler: this._petName, substeps: 1 },
        { name: 'petEnd', handler: this._petEnd, substeps: 3 },
    ];

    // props
    //-------------------------------------------------------------------------
    // step
    private get step(): number {
        return app.server.state.tutorial.pets.step;
    }

    private set step(step: number) {
        app.server.invoke.tutorialPetsSetStep({ step });
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        this._petsTutorialOffset = this._steps.reduce(
            (acc, cur) => {
                acc.push(acc[acc.length - 1] + cur.substeps);
                return acc;
            },
            [0],
        );
    }

    // impl
    //-------------------------------------------------------------------------
    async execute(): Promise<void> {
        // this.step = 2;

        // run tutorial
        await this._run();

        // complete
        await this._complete();
    }

    // private: control
    //-------------------------------------------------------------------------
    private async _run() {
        // track overall tutorial start
        trackTutorialStart({ stepIndex: 0, stepName: id });

        // for each tutorial step
        for (; this.step < this._steps.length; this.step++) {
            const entry = this._steps[this.step];
            const stepName = `${id}.${entry.name}`;

            // execute tutorial
            await entry.handler.call(this, {
                stepName,
            });
        }
    }

    private async _complete() {
        // complete tutorial
        await app.server.invoke.tutorialPetsComplete();

        trackTutorialFinish({ stepIndex: this._petsTutorialOffset[this.step], stepName: id });

        // close any popups
        await app.nav.closeLayer(NavLayer.popup);
    }

    // private: steps
    //-------------------------------------------------------------------------
    private async _petIntro() {
        let stepIndex = this._petsTutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `help_1` });
        const screen = (this._petScreen = (await app.nav.open('petScreen', { initState: 'speech1' })) as PetScreen);
        screen.isTalking = true;
        const speechPromise1 = new NakedPromise();
        await screen.spawnSpeechSequence('speech1', speechPromise1);
        await speechPromise1;
        trackTutorialStepFinish({ stepIndex, stepName: `help_1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `help_2` });
        const speechPromise2 = new NakedPromise();
        await screen.spawnSpeechSequence('speech2', speechPromise2);
        await speechPromise2;

        trackTutorialStepFinish({ stepIndex, stepName: `help_2` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `treat_1` });

        // reset header values
        screen.healthProgress.setProgress(0.5);
        screen.foodProgress.setProgress(0.5);

        // TODO ANIMATE
        // TODO ANIMATE
        // TODO ANIMATE
        screen.headerView.alpha = 1;
        const buttons = screen.spawnMenu(['health'], true);

        const treatPromise = new NakedPromise();
        // override
        buttons[0].onPress = async () => treatPromise.resolve();
        app.nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [buttons[0].getBounds()],
        });
        await treatPromise;

        trackTutorialStepFinish({ stepIndex, stepName: `treat_1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `treat_2` });

        app.nav.close('tipScreen');

        const popupId = 'petHelpPopup';
        const petHelpPopup = (await app.nav.open(popupId, {
            items: helpMap['treat'].items,
            title: helpMap['treat'].title,
        })) as PetHelpPopup;

        const tapPromise = new NakedPromise();

        petHelpPopup.items.forEach((item) => (item.onPress = null));
        petHelpPopup.items[0].onPress = async () => tapPromise.resolve();
        app.nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [petHelpPopup.items[0].getBounds()],
        });

        await tapPromise;
        app.sound.play('buff1.ogg', { volume: 0.5 });
        setTimeout(async () => {
            // TODO REAL ANIMATION
            for (let i = 1; i <= 25; i++) {
                screen.healthProgress.setProgress(0.5 + i * 0.01);
                await sleep(0.012);
            }
        }, 0.5);

        await sleep(0.2);
        app.nav.close('tipScreen');
        app.nav.close(popupId);

        this._petScreen.despawnMenu();
        await screen.playPetAnimation('init_injured_to_idle');
        screen.playPetAnimation('idle_happy', true);
        // happy for short amount then switch
        await sleep(0.55);
        // screen.isTalking = false;

        const bubble1 = await this._petScreen.spawnSpeechBubble('[petTutorialBubble0]');
        await sleep(1.25);
        await this._despawnView(bubble1);
        const bubble2 = await this._petScreen.spawnSpeechBubble('[petTutorialBubble1]');
        await sleep(1.25);

        screen.playPetAnimation('idle_normal', true);
        await this._despawnView(bubble2);

        // small pause before next step
        // await sleep(0.5);
        trackTutorialStepFinish({ stepIndex, stepName: `treat_2` });
    }

    private async _petName() {
        const stepIndex = this._petsTutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `name` });
        if (!this._petScreen) {
            this._petScreen = (await app.nav.open('petScreen', { initState: 'name' })) as PetScreen;
        }

        this._petScreen.healthProgress.setProgress(0.75);
        this._petScreen.foodProgress.setProgress(0.5);

        this._petScreen.isTalking = true;

        const bubble1 = await this._petScreen.spawnSpeechBubble('[petTutorialBubble2]');

        const namePopupId = 'petNamePopup';
        const namePromise = new NakedPromise<string>();
        (await app.nav.open(namePopupId, { onConfirm: namePromise })) as PetNamePopup;
        const name = await namePromise;
        // render new name
        this._petScreen.setSetPetName(name);

        app.nav.close(namePopupId);
        await this._despawnView(bubble1);
        this._petScreen.playPetAnimation('idle_happy', true);

        const bubble2 = await this._petScreen.spawnSpeechBubble(`[petTutorialBubble3|${name}]`);

        // store name before exiting the step
        await app.server.invoke.playerSetName({ name });
        await sleep(1);
        await this._despawnView(bubble2);
        this._petScreen.playPetAnimation('idle_normal', true);
        trackTutorialStepFinish({ stepIndex, stepName: `name` });
    }

    private async _petEnd() {
        let stepIndex = this._petsTutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `feed_1` });
        if (!this._petScreen) {
            this._petScreen = (await app.nav.open('petScreen', { initState: 'custom' })) as PetScreen;
        }

        this._petScreen.healthProgress.setProgress(0.75);
        this._petScreen.foodProgress.setProgress(0.5);

        this._petScreen.isTalking = true;
        this._petScreen.playPetAnimation('idle_hungry', true);
        const bubble2 = await this._petScreen.spawnSpeechBubble(`[petTutorialBubble4]`);
        await sleep(1);

        const buttons = this._petScreen.spawnMenu(['health', 'nourish'], true);
        buttons[0].onPress = null;
        const feedPromise = new NakedPromise();
        buttons[1].onPress = async () => feedPromise.resolve();
        app.nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            // nourish button
            targets: [buttons[1].getBounds()],
        });
        await feedPromise;
        trackTutorialStepFinish({ stepIndex, stepName: `feed_1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `feed_2` });
        app.nav.close('tipScreen');

        const popupId = 'petHelpPopup';
        const petHelpPopup = (await app.nav.open(popupId, {
            items: helpMap['forage'].items,
            title: helpMap['forage'].title,
        })) as PetHelpPopup;

        const tapPromise = new NakedPromise();
        petHelpPopup.items.forEach((item) => (item.onPress = null));
        petHelpPopup.items[0].onPress = async () => tapPromise.resolve();
        app.nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [petHelpPopup.items[0].getBounds()],
        });

        await tapPromise;
        await sleep(0.2);
        app.nav.close('tipScreen');
        app.nav.close(popupId);

        this._petScreen.despawnMenu();
        await this._despawnView(bubble2);

        setTimeout(async () => {
            // TODO REAL ANIMATION
            for (let i = 1; i <= 25; i++) {
                this._petScreen.foodProgress.setProgress(0.5 + i * 0.01);
                await sleep(0.012);
            }
        }, 0.5);

        app.sound.play('buff1.ogg', { volume: 0.5 });
        await this._petScreen.playPetAnimation('eat');

        this._petScreen.playPetAnimation('idle_happy', true);
        const dewFlower = textLocaleFormat(`[itemPetsDewFlower]`);
        const bubble3 = await this._petScreen.spawnSpeechBubble(`[petTutorialBubble5|${dewFlower}]`);

        // small pause before next step
        await sleep(0.5);
        await this._despawnView(bubble3);
        await sleep(0.5);
        trackTutorialStepFinish({ stepIndex, stepName: `feed_2` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `closing_thoughts` });

        // bubble with tap block
        const finalPromise = new NakedPromise();
        await this._petScreen.spawnBubbleTap(finalPromise);

        await finalPromise;
        trackTutorialStepFinish({ stepIndex, stepName: `closing_thoughts` });
        this._petScreen.isTalking = false;
        // tap to close, finish tutorial
    }

    private async _despawnView(view: Container) {
        await view.animate().add(view, { alpha: 0 }, 0.3, tween.pow2Out);
        view.removeSelf();
    }
}
